import logo from './logo.svg';
import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const shopingCart = process.env.PUBLIC_URL+"/assets/icons/shopping-cart-fast.svg "
const Send = process.env.PUBLIC_URL+"/assets/icons/send.svg "

function App() {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [quote,setQuote] = useState("")
  const [loading,setLoading] = useState(true)
  const [imageLoading, setImageLoading] = useState(true);
  const ref = useRef(null);


  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  };

const url = window.location.href
  const getQuote =  async()=>{
  
    try{
      const date  = new Date()
      
      await axios.post("https://messiahbracelets.com/portal/api/v1/get_quotes-by-date",{quote_date:`${formatDate(date)}`}).then((res)=>{
        if(res.data.quote_image){
          setQuote(res.data.quote_image)
        }else{
          setQuote(res.data.image)
        }

      }).finally(()=>setLoading(false))
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    getQuote()
  },[])

  const preloadImage = (src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImageLoading(false); 
    };
  };

  useEffect(() => {
    if (quote) {
      preloadImage(quote); // Start preloading the image after the quote is fetched
    }
  }, [quote]);


  const handleClick = async (event) => {
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(url);
      setShow(true)
      setTarget(event.target);
      setTimeout(() => {
        setShow(false);
    }, 5000); 
    } catch (err) {
      setShow(false)
    }
   

  };
console.log(quote)
  return (
    <React.Fragment>
      {(loading || imageLoading) && (
         <div style={{height:"100vh",display:"flex",justifyContent:"center",alignItems:"center",position:"relative",zIndex:9999,backgroundColor:"#FFF"}} >
          <Spinner  />
         </div>
      )}
   
    <div  className="App" style={{ background: `url('${quote}')` }} >
      <footer  >
        <div className='container'  >
          <div className='row px-2 '   >
            <div className='col-5 col-lg-4 text-start' >
              <a href='https://messiahverse.com/' target='_blank'   >
              <div className='icon-container' >
                <img src={shopingCart} className='icon-img  ' />
              </div>
              </a>
            </div>

            <div className='col-5 col-lg-4 text-end d-flex justify-content-end  ' >
             
              <a ref={ref} style={{ cursor: "pointer",position:"relative"}} onClick={handleClick}  >
        <div className='icon-container' >
              <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref}
                containerPadding={20}
                
              >
                <Popover id="popover-contained" style={{position:"absolute",minWidth:260}} >

                  <Popover.Body className='text-center' >
                    <strong>
                    Link copied! Now spread the Word.
                    </strong>
                  </Popover.Body>
                </Popover>
              </Overlay>
              <img src={Send} className='icon-img  ' />
              </div>
              </a>
            </div>

          </div>
        </div>
      </footer>
      
    </div>
    </React.Fragment>
  );
}

export default App;
